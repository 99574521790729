import React, {useEffect, useState} from 'react';
import SEO from '../../components/seo';
import TitleSlider from '../../components/desktop/TitleSlider/TitleSlider';
import TitleSliderMobile from '../../components/mobile/TitleSlider/TitleSlider';
import Form from '../../components/desktop/Form/Form';
import Grid from '../../components/desktop/Grid/Grid';
import Foto1 from '../../images/FOTO_1.png';
import Foto2 from '../../images/FOTO_2.png';
import Foto3 from '../../images/FOTO_3.png';

const IndexPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.innerWidth < 768 && setIsMobile(true);
    }
  }, []);

  return (
    <>
      <SEO title="Strona główna" />
      {!isMobile ? <TitleSlider /> : <TitleSliderMobile />}
      <Grid
        backgroundColor={'#a3c0c0'}
        backgroundImage={Foto1}
        reverse={false}
        sectionTitle={'O ARTYŚCIE'}
        navigateTo="/pl/artist-1"
        sectionText={`&emsp;&emsp;Twórczość Jana Szczepkowskiego mijamy często w&nbsp;Krakowie i&nbsp;Warszawie nieświadomie w&nbsp;codziennym kieracie obowiązków. W&nbsp;Krakowie obecne są jego płaskorzeźby czterech ewangelistów na sklepieniu kościoła św. Floriana oraz pomnik Henryka Jordana. A&nbsp;po udaniu się do Warszawy wystarczy zobaczyć elewację Sejmu, płaskorzeźby na gmachu BGK oraz pomniki Moniuszki i&nbsp;Bogusławskiego na Placu Teatralnym. A&nbsp;przecież jego najbardziej znanym dziełem jest nagrodzona w&nbsp;1925 r. Kapliczka Bożego Narodzenia. Jego wkładu w&nbsp;kultywowanie pamięci o&nbsp;Marszałku Józefie Piłsudskim nie sposób przecenić. Wystarczy się rozejrzeć. A&nbsp;ta strona jest dobrym punktem startu do poznania twórczości tego niezwykłego artysty. `}
      />
      <Grid
        backgroundColor={'#e09087'}
        backgroundImage={Foto2}
        reverse={true}
        sectionTitle={'O MUZEUM'}
        navigateTo="/pl/about-1"
        sectionText={`&emsp;&emsp;Hasło „muzeum” do dziś kojarzy się z&nbsp;ograniczeniami, nieśmiertelnymi filcowymi kapciami, nabożną ciszą albo malutkimi karteczkami powieszonymi na eksponatach, których często nie czytamy. Tak jest w&nbsp;wielu stacjonarnych muzeach. Ale nasze muzeum jest inne, bo wirtualne i&nbsp;jedyne ograniczenie to nasza wyobraźnia. Bo znajdujemy się w&nbsp;największej przestrzeni wystawienniczej na świecie, czyli w&nbsp;Internecie! Można nas odwiedzić każdego dnia i&nbsp;o każdej porze. Do wirtualnego zwiedzenia czeka 120 zdigitalizowanych dzieł Jana Szczepkowskiego (w tym 60 modeli 3d) i&nbsp;ponad 100 archiwalnych fotografii. Zapraszamy już teraz do poznania dorobku czołowego artysty II Rzeczpospolitej! w&nbsp;kapciach lub bez.`}
      />
      <Grid
        backgroundColor={'#e0c887'}
        backgroundImage={Foto3}
        reverse={false}
        sectionTitle={'DZIEŁA'}
        buttonText="Obejrzyj dzieła"
        navigateTo="/pl/arts"
        sectionText={`&emsp;&emsp;Postanowiliśmy podzielić zebrany w&nbsp;Milanówku dorobek artystyczny Jana Szczepkowskiego na cztery kategorie. Pierwszy dział Przedstawienie postaci skupia się na rzeźbach, figurach i&nbsp;popiersiach powstałych spod dłuta Szczepkowskiego. Drugi dział Marszałek Józef Piłsudski grupuje cały dorobek poświęcony osobie Naczelnika. Trzeci dział Płaskorzeźby przedstawia kompozycje rzeźbiarskie Jana Szczepkowskiego wykonane w&nbsp;płycie gipsowej lub drewnianej. Ostatni dział Pozostałe dzieła zawiera te eksponaty, które nie znalazły się w&nbsp;trzech wcześniejszych kategoriach. Są to m.in. innymi rozwiązania urbanistyczne dla Warszawy, medale okolicznościowe i&nbsp;meble zaprojektowane przez artystę. `}
      />
      <Form />
    </>
  );
};

export default IndexPage;
