import React from 'react';
import {
  SlideWrapper,
  ImageWrapper,
  SlidePrimeText,
  SlideSecondaryTextWrapper,
  SlideSecondaryLogo,
  SlideSecondaryText,
  Dots,
  text,
  HaniaWrapper,
} from './styles';
import slide1 from '../../../images/slider_1.jpg';
import slide2 from '../../../images/slider_2.jpg';
import LogoSvg from '../../../images/logo_slider.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slide2a2 from '../../../images/slider_2a2.jpg';

const SlidePrime = () => (
  <SlideWrapper>
    <SlidePrimeText isPolish>
      <p>120 rzeźb</p>
      <p>60 modeli 3d</p>
      <p>100 fotografii</p>
    </SlidePrimeText>
    <ImageWrapper src={slide1} />
  </SlideWrapper>
);

const SlideSecondary = () => (
  <SlideWrapper>
    <SlideSecondaryTextWrapper>
      <SlideSecondaryLogo src={LogoSvg} />
    </SlideSecondaryTextWrapper>
    <ImageWrapper src={slide2} />
  </SlideWrapper>
);

const SlideThertiary = () => (
  <SlideWrapper>
    <HaniaWrapper src={slide2a2} />
  </SlideWrapper>
);

const TitleSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    appendDots: (dots) => <Dots>{dots}</Dots>,
  };
  return (
    <Slider {...settings}>
      <SlideSecondary />
      <SlidePrime />
      <SlideThertiary />
    </Slider>
  );
};

export default TitleSlider;
