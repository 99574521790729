import React, {useState} from 'react';
import {SectionTitle, SectionText, ActionButton} from '../../atoms';
import {Container} from '../../global';
import {
  FormWrapper,
  FormFlex,
  FormColumn,
  FormTitle,
  FormContainer,
  FormInput,
  FormTextarea,
  FormSubmit,
  FlexLine,
  SocialIcon,
  FormAgree,
} from './styles';
import IconMail from '../../../images/mail.svg';
import IconFB from '../../../images/facebook.svg';
import IconYT from '../../../images/youtube.svg';
import IconInstagram from '../../../images/instagram.svg';
import emailjs from 'emailjs-com';

const User = 'user_kVOGuM96FHsDB1OK1GkVK';
const Token = '206b8a8dc33f19470bbca356c6c11e9e';

const Form = () => {
  const [isAgree, setAgree] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    mail: '',
    subject: '',
    message: '',
  });
  const onInputChange = (e) => {
    setFormValues({...formValues, [e.target.name]: e.target.value});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        'service_7b834zj',
        'template_c6q616g',
        {
          from_email: formValues.mail,
          from_name: formValues.name,
          from_topic: formValues.subject,
          message: formValues.message,
        },
        User,
      )
      .then((result) => {
        // alert('a');
        setFormValues({name: '', mail: '', subject: '', message: ''});
      })
      .catch((err) => {
        alert('Błąd wysyłania formularza. Spróbuj ponownie');
      });
  };

  return (
    <FormWrapper id="contact">
      <Container>
        <FormFlex>
          <FormColumn>
            <FormTitle>Formularz Kontaktowy</FormTitle>
            <FormContainer>
              <form onSubmit={onSubmit}>
                <FormInput
                  name="name"
                  type="text"
                  placeholder="Imie i nazwisko"
                  onChange={onInputChange}
                  value={formValues.name}
                />
                <FormInput
                  name="mail"
                  type="email"
                  placeholder="E-mail"
                  onChange={onInputChange}
                  value={formValues.mail}
                />
                <FormInput
                  name="subject"
                  type="text"
                  placeholder="Temat"
                  onChange={onInputChange}
                  value={formValues.subject}
                />
                <FormTextarea
                  name="message"
                  placeholder="Treść wiadomości"
                  onChange={onInputChange}
                  value={formValues.message}
                />

                <FormAgree>
                  <div>
                    <input
                      type="checkbox"
                      name="agree"
                      onChange={(e) => setAgree(e.target.checked)}
                      checked={isAgree}
                    />
                  </div>
                  <p>
                    <a
                      href="https://mckmilanowek.pl/kontakt-2/polityka-prywatnosci/"
                      target="_blank">
                      Zapoznałam/em się z informacjami o administratorze oraz o
                      przetwarzaniu moich danych osobowych.
                    </a>{' '}
                    Wyrażam zgodę na przetwarzanie tych danych.
                  </p>
                </FormAgree>
                <FormSubmit
                  style={{opacity: !isAgree && '0.6'}}
                  type="submit"
                  onClick={(e) => !isAgree && e.preventDefault()}>
                  WYŚLIJ
                </FormSubmit>
              </form>
            </FormContainer>
          </FormColumn>
          <FormColumn>
            <SectionTitle>Kontakt</SectionTitle>
            <SectionText margin={'1rem 0 1rem'}>
              &emsp;&emsp;Chcesz podzielić się swoimi wrażeniami
              z&nbsp;odwiedzin naszego Wirtualnego Muzeum? Potrzebujesz
              materiałów ze strony w&nbsp;lepszej jakości do swojej pracy
              naukowej lub szkolnej? A&nbsp;może masz jeszcze inną sprawę?
              Wszystkich zainteresowanych kontaktem z&nbsp;Wirtualnym Muzeum
              Jana Szczepkowskiego zachęcamy do skorzystania z&nbsp;poniższego
              formularza. Odpowiemy na każdą wysłaną wiadomość!
            </SectionText>
            {/* <ActionButton color="blue">Czytaj dalej</ActionButton> */}

            <FlexLine style={{marginTop: '1rem'}}>
              <SocialIcon src={IconMail} />
              <SectionText>muzeum.szczepkowskiego@gmail.com</SectionText>
            </FlexLine>
            <FlexLine href="https://www.facebook.com/muzeumszczepkowskiego">
              <SocialIcon src={IconFB} />
              <SectionText>facebook.com/muzeumszczepkowskiego</SectionText>
            </FlexLine>
            <FlexLine href="https://www.instagram.com/muzeum.szczepkowskiego">
              <SocialIcon src={IconInstagram} />
              <SectionText>instagram.com/muzeum.szczepkowskiego</SectionText>
            </FlexLine>
            <FlexLine href="https://www.youtube.com/channel/UCHfT0NabOoNPx2DR-BmsD8Q">
              <SocialIcon src={IconYT} />
              <SectionText>youtube.com/muzeumszczepkowskiego</SectionText>
            </FlexLine>
          </FormColumn>
        </FormFlex>
      </Container>
    </FormWrapper>
  );
};

export default Form;
